import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

// Connects to data-controller="sortable"
export default class SortableController extends Controller {
  static values = {
    animation: {
      type: Number,
      default: 150
    },
    handle: String,
    paramName: {
      type: String,
      default: "position",
    },
    resourceName: String
  };

  connect() {
    this.options = {
      dataIdAttr: "data-sortable-id",
      animation: this.animationValue,
      handle: this.handleValue,
      onUpdate: this.onUpdate
    };

    this.sortable = new Sortable(this.element, this.options);
  }

  disconnect() {
    // FIXME: we don't destroy Sortable instances while dragging
    // because it causes things to break.
    //
    // See:
    // https://github.com/stimulus-components/stimulus-sortable/issues/10
    // https://github.com/stimulus-components/stimulus-sortable/issues/16
    if (!Sortable.active) {
      let el = Sortable.get(this.element);
      if (el) el.destroy();
    }
  }

  onUpdate = ({item, newIndex, oldIndex, from}) => {
    const itemAtOldIndex = Sortable.utils.getChild(this.element, oldIndex, this.options);

    let newPosition;
    if (newIndex > oldIndex && item.previousElementSibling && item.previousElementSibling.dataset.sortableId) {
      newPosition = JSON.stringify({ after: item.previousElementSibling.dataset.sortableId });
    }
    else if (item.nextElementSibling && item.nextElementSibling.dataset.sortableId) {
      newPosition = JSON.stringify({ before: item.nextElementSibling.dataset.sortableId });
    }
    else if (item.previousElementSibling && item.previousElementSibling.dataset.sortableId) {
      newPosition = JSON.stringify({ after: item.previousElementSibling.dataset.sortableId });
    }
    else {
      const currentPage = (new URL(document.location)).searchParams.get("page") || 1;
      const perPage = (new URL(document.location)).searchParams.get("per_page") || 12;
      newPosition = newIndex + ((currentPage - 1) * perPage) + 1;
    }

    return fetch(item.dataset.sortableUpdateUrl, {
      method: "PUT",
      headers: { 'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content },
      body: this.positionFormData(newPosition)
    }).then((res) => {
      if (res.status < 200 || res.status > 299) {
        return Promise.reject(res);
      }
    }).catch((error) => {
      from.insertBefore(item, itemAtOldIndex);
      return Promise.reject(error);
    });
  }

  positionFormData(position) {
    const data = new FormData();
    data.append(
      this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue,
      position
    );
    return data;
  }
}
